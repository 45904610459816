import React, { useEffect } from "react";
import Minus from "../../../static/icons/Minus";
import Plus from "../../../static/icons/Plus";
import { NftData } from "../../../types/nftData";
import { EventData } from "../../../types/provenanceData";
import ButtonsContainer from "../../ButtonsContainer";
import Carousel from "../../Carousel";
import HistoryTitle from "../../HistoryTitle";
import VideoNFT from "../../VideoNFT";
import Witnesses from "../../Witnesses";

import {
  AccordeonBody,
  AccordeonBodyData,
  AccordeonBodyHeader,
  AccordeonButton,
  AccordeonContainer,
  AccordeonHeader,
  AccordeonTitle,
  EndData,
  IsLiveBanner,
  StartData,
} from "./styles";

const Accordeon: React.FC<{
  event: EventData;
  instanceName: string;
  nftID: string;
  nftData: NftData;
}> = ({ event, instanceName, nftID, nftData }) => {
  const [isShowedAcc, setIsShowedAcc] = React.useState(true);

  const dateNow = new Date();
  const needToWait = dateNow.getTime() > event.startDate * 1000;
  const isFinished = dateNow.getTime() > event.endDate * 1000;
  const current = !needToWait && isFinished;

  const handleShowAcc = () => {
    setIsShowedAcc(!isShowedAcc);
    if (isShowedAcc) {
      window.localStorage.removeItem(`showEvent${event.eventId}`);
    } else {
      window.localStorage.setItem(`showEvent${event.eventId}`, "true");
    }
  };

  function padTo2Digits(num: number) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(d: number) {
    const date = new Date(d * 1000);
    return [
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
      date.getFullYear(),
    ].join(".");
  }

  return (
    <>
      {!current ? (
        <AccordeonContainer id={"e" + event.eventId.toString()}>
          <AccordeonHeader>
            <AccordeonBodyHeader>
              <IsLiveBanner />
              <AccordeonBodyData>
                <AccordeonTitle>{event.title}</AccordeonTitle>
                <StartData>{formatDate(Number(event.startDate))} </StartData>
                <EndData>- {formatDate(Number(event.endDate))}</EndData>
              </AccordeonBodyData>
              <AccordeonButton onClick={handleShowAcc}>
                {!isShowedAcc ? <Plus /> : <Minus />}
              </AccordeonButton>

            </AccordeonBodyHeader>
            <ButtonsContainer
              instanceName={instanceName}
              needToWait={!needToWait}
              isFinished={isFinished}
              eventID={event.eventId}
              nftID={nftID}
            />

            <Witnesses eventID={event.eventId} nftID={nftID} />
          </AccordeonHeader>
          <div style={{height: 100}}></div>
          <HistoryTitle title={nftData.name} />
          <VideoNFT src={nftData.animation_url} />
          {isShowedAcc && (
            <AccordeonBody>
              {event.images.length ? <Carousel images={event.images} /> : null}
            </AccordeonBody>
          )}
        </AccordeonContainer>
      ) : null}
    </>
  );
};

export default Accordeon;
