import styled from "@emotion/styled";

export const Button = styled("button")`
  font-size: 20px;
  font-weight: 600;
  color: ${(props) => (!props.disabled ? "#fff" : "grey")};
  padding: 18px 45px;
  border-radius: 13px;
  border: none;
  background: ${(props) => (!props.disabled ? "#000000" : "#f9f9f9")};
  backdrop-filter: blur(80px);
  margin: 30px 0;
  cursor: pointer;
`;

export const LoadingText = styled("span")(() => ({
  fontSize: 12,
  fontWeight: 600,
  marginBottom: 20,
}));
