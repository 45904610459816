import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../redux";
import { NftData } from "../../types/nftData";
import { EventData } from "../../types/provenanceData";
import HistoryTitle from "../HistoryTitle";
import VideoNFT from "../VideoNFT";
import CurrentAccordeon from "./CurrentAccordeon";
import { StyledEvent } from "./styles";

const CurrentEvent: React.FC<{
  nftID: string;
  nftData: NftData;
}> = ({ nftID, nftData }) => {
  const event = useAppSelector((state : any) => state.event.event);

  const eventNames: any = {
    "1": "Chestplate of Regeneration",
    "2": "Pauldrons of Reciprocity",
    "3": "Visor of the Infinite Game",
  };

  const currentEvent = event?.filter((event: EventData) => {
    const dateNow = new Date();
    const needToWait = dateNow.getTime() > event.startDate * 1000;
    const isFinished = dateNow.getTime() > event.endDate * 1000;
    const current = needToWait && !isFinished;
    return current;
  });

  return (
    <StyledEvent>
      {currentEvent?.length ? (
        <CurrentAccordeon
          event={currentEvent[0]}
          instanceName={eventNames[nftID]}
          nftID={nftID}
          nftData={nftData}
        />
      ) : (
        <>
          <HistoryTitle title={nftData.name} />
          <VideoNFT src={nftData.animation_url} />
        </>
      )}
    </StyledEvent>
  );
};

export default CurrentEvent;
