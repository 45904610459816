import React, { useEffect } from "react";
import contractInstance from "../../blockchain/web3";

import Accordeon from "../Accordeon";
import { EventData } from "../../types/provenanceData";

import { StyledEventContainer } from "./styles";
import Spinner from "../Spinner";
import { useDispatch } from "react-redux";
import { setEvents } from "../../redux/auth/slice";
import { useAppSelector } from "../../redux";

const EventContainer: React.FC<{
  nftID: string;
}> = ({ nftID }) => {
  const event = useAppSelector((state : any) => state.event.event);
  const dispatch = useDispatch();

  const eventNames: any = {
    "1": "Chestplate of Regeneration",
    "2": "Pauldrons of Reciprocity",
    "3": "Visor of the Infinite Game",
  };

  useEffect(() => {
    const updateProvenance = async (instanceName: string) => {
      let eventData = await getEvents(instanceName);
      eventData = eventData.filter((event : any) => !event[0].startsWith("Pre-Denver"))
      dispatch(setEvents(eventData));
    };
    updateProvenance(eventNames[nftID]);
  }, []);

  const getEvents = async (instanceName: string) => {
    const response = await contractInstance.methods
      .getEvents(instanceName)
      .call();
    return response;
  };

  const sortedEvents = event
    ? [...event].sort((a, b) => a.startDate - b.startDate)
    : [];

  return (
    <StyledEventContainer>
      {event ? (
        sortedEvents.map((event, index) => {
          return (
            <Accordeon
              event={event}
              key={index}
              instanceName={eventNames[nftID]}
              nftID={nftID}
            />
          );
        })
      ) : (
        <Spinner />
      )}
    </StyledEventContainer>
  );
};

export default EventContainer;
