import styled from "@emotion/styled";

export const CarouselContainer = styled("div")`
  overflow-x: scroll;
  display: flex;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const CarouselItem = styled("img")(() => ({
  width: 312,
  height: 208,
  margin: "20px 16px",
}));
