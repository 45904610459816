import React, { useEffect, useState } from "react";
// import { checkNetwork, networkID } from "../../blockchain/MetaMaskConnect";
import { useAppSelector } from "../../redux";
import { ButtonsType } from "../../types/button";
import MainButton from "../MainButton";
import { BtnsContainer } from "./styles";

const ButtonsContainer: React.FC<ButtonsType> = ({
  needToWait,
  isFinished,
  eventID,
  instanceName,
  nftID,
}) => {
  const [isRightNetwork, setIsRightNetwork] = useState<boolean>(false);
  const account = useAppSelector((state : any) => state.wallet.address);

  // useEffect(() => {
  //   if (window.ethereum) {
  //     window.ethereum.on("networkChanged", function () {
  //       const checkIsRightNetwork = async () => {
  //         const isRight = await checkNetwork(networkID);
  //         if (isRight) {
  //           setIsRightNetwork(false);
  //         } else {
  //           setIsRightNetwork(true);
  //         }
  //       };
  //       checkIsRightNetwork();
  //     });
  //   }
  // }, [isRightNetwork]);

  return (
    <BtnsContainer>
      {account && (
        <MainButton
          instanceName={instanceName}
          needToWait={needToWait}
          isFinished={isFinished}
          eventID={eventID}
          isRightNetwork={isRightNetwork}
          nftID={nftID}
        />
      )}
    </BtnsContainer>
  );
};

export default ButtonsContainer;
