import styled from "@emotion/styled";

export const AccordeonContainer = styled("div")(() => ({
  width: "100%",
}));

export const AccordeonHeader = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  padding: "16px 0 0",
}));

export const AccordeonTitle = styled("h4")(() => ({
  fontWeight: 700,
  fontSize: 20,
  color: "#000000",
}));

export const AccordeonButton = styled("div")(() => ({
  cursor: "pointer",
}));

export const IsLiveBanner = styled("div")(() => ({
  height: "80px",
  position: "relative",
  "&:after": {
    position: "absolute",
    backgroundColor: "#e21251",
    textAlign: "center",
    content: "'Live'",
    width: "235px",
    transform: "rotate(-31.32deg)",
    height: "50px",
    color: "white",
    top: "-14px",
    left: "-70px",
    fontWeight: 600,
    fontSize: 20,
    lineHeight: 2.3,
  },
}));

export const AccordeonBody = styled("div")(() => ({}));

export const AccordeonBodyHeader = styled("div")(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "space-between",
  fontSize: 12,
  color: "#828282",
  padding: "10px 0 0",
}));

export const AccordeonBodyInfo = styled("div")(() => ({}));

export const AccordeonBodyData = styled("span")(() => ({
  textAlign: "center",
}));

export const StartData = styled("span")(() => ({
  marginBottom: 3,
}));

export const EndData = styled("span")(() => ({}));

export const AccordeonBodyLocation = styled("span")(() => ({}));
