import MetaMaskOnboarding from "@metamask/onboarding";
import Web3 from "web3";

const GNOSIS_MAINNET_PARAMS = {
  chainId: "0x64",
  chainName: "Gnosis",
  nativeCurrency: {
    name: "xDai",
    symbol: "xDai",
    decimals: 18,
  },
  rpcUrls: ["https://rpc.gnosischain.com/"],
  blockExplorerUrls: ["https://gnosisscan.io/"],
};

export const networkID = 100;

const isMetaMaskInstalled = () => {
  return Boolean(window.ethereum && window.ethereum.isMetaMask);
};

const installMetaMask = () => {
  let onboarding = new MetaMaskOnboarding();
  onboarding.startOnboarding();
};

export async function checkNetwork(network: number) {
  let chain;
  try {
    chain = await window.ethereum.request({
      method: "eth_chainId",
      params: [],
    });
  } catch (error) {
    console.error(error);
  }
  chain = Web3.utils.toNumber(chain);

  return chain === network;
}

export async function requestAccounts() {
  let accounts;
  try {
    accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
  } catch (error) {
    console.error(error);
  }
  return accounts[0];
}

export async function switchEthereumChain(network: number) {
  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: Web3.utils.toHex(network) }],
    });
    window.location.reload();
  } catch (error) {
    await AddGnosisNetwork();
    // window.location.reload();
    console.error(error);
  }
}

export async function MetaMaskClientCheck(network: number) {
  if (!isMetaMaskInstalled()) {
    installMetaMask();
  } else {
    const account = await requestAccounts();
    await switchEthereumChain(network);
    await AddGnosisNetwork();
    return account;
  }
}

export async function AddGnosisNetwork() {
  try {
    window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [GNOSIS_MAINNET_PARAMS],
    });
  } catch (error) {
    console.log(error);
  }
}
