import styled from "@emotion/styled";

export const TitleNftContainer = styled("div")(() => ({
  borderTop: "1px solid #000000",
  padding: "10px 16px 46px",
  background: "#F5F5F5",
  width: "100%",
  maxWidth: 500,
}));

export const TitleNftHeader = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));

export const TitleNftTitle = styled("h4")(() => ({
  fontSize: 20,
  fontWeight: 700,
  lineHeight: "30px",
  letterSpacing: -0.41,
}));

export const TitleNftDescription = styled("p")(() => ({
  fontSize: 14,
  fontWeight: 400,
  textAlign: "justify",
  lineHeight: "21px",
  letterSpacing: -0.41,
  marginTop: 5,
}));
