import styled from "@emotion/styled";

export const StyledEventContainer = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  max-width: 500px;
  padding: 16px;
  & > div:not(:last-child) {
    padding-bottom: 16px;
    border-bottom: 1px solid #000000;
  }
`;
