import styled from "@emotion/styled";

export const Button = styled("button")`
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
  padding: 18px 18px;
  border-radius: 13px;
  border: none;
  background: #000;
  backdrop-filter: blur(80px);
  cursor: pointer;
`;
