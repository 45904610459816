import Web3 from "web3";
import SmartContractsInfo from "./ContractInfo.json";

const { address, abi } = SmartContractsInfo.smartContractProvenance;

export var web3;
if (typeof window !== "undefined" && typeof window.ethereum !== "undefined") {
  // We are in the browser and metamask is running.
  web3 = new Web3(window.ethereum);
} else {
  // We are on the server *OR* the user is not running metamask
  const provider = new Web3.providers.HttpProvider(
    "https://capable-warmhearted-research.xdai.discover.quiknode.pro/1551569df7ab32f0a1a02b5aedc74989df8810a4/"
  );
  web3 = new Web3(provider);
}

const contractInstance = new web3.eth.Contract(abi, address, {
  from: "0x168589359268cd69674bb3ae089ec83697c16cb4",
});

export default contractInstance;
