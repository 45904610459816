import React from "react";
import { CarouselContainer, CarouselItem } from "./styles";

const Carousel: React.FC<{ images: Array<string> }> = ({ images }) => {
  return (
    <CarouselContainer>
      {images.map((image, id) => (
        <CarouselItem src={image} key={id}></CarouselItem>
      ))}
    </CarouselContainer>
  );
};

export default Carousel;
