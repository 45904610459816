import styled from "@emotion/styled";

export const VideoNftContainer = styled("div")(() => ({
  padding: 20,
  background: "#F5F5F5",
  maxWidth: 500,
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
