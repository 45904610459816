import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Link,
} from "react-router-dom";
import { AdminRoutes } from "./config/routes";

import SuccessPage from "./pages/SuccessPage";
import ConnectPage from "./pages/ConnectPage";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        {/* <Route index element={<ConnectPage />} /> */}
        <Route path={"/"} element={<p>Hello world! <Link to={"/moloch/?id=2"}>Click!</Link></p>} />
        <Route path={"/moloch"} element={<ConnectPage />} />
        <Route path={"/moloch/"} element={<ConnectPage />} />
        <Route path={"/moloch//"} element={<ConnectPage />} />
        <Route path={"/moloch/.*"} element={<ConnectPage />} />
        <Route path={"/moloch/success-page"} element={<SuccessPage />} />
        {/* <Route
          path='*'
          element={<Navigate to={AdminRoutes.ConnectPage} replace />}
        /> */}
      </Routes>
    </Router>
  );
};

export default App;
 