import React from "react";
import { Button } from "./styles";

const ConnectStateButton: React.FC<{
  connect: () => void;
}> = ({ connect }) => {
  return <Button onClick={() => connect()}>Connect wallet to witness</Button>;
};

export default ConnectStateButton;
