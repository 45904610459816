import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Button } from "../../components/MainButton/styles";
import { PageContainer } from "../ConnectPage/styles";
import {
  SuccessDescPrimary,
  SuccessDescSecondary,
  GoBack,
  StyledSuccessContainer,
  StyledSuccessTitle,
  SuccessContainerSecondary,
  GoBackContainer,
  CaveButton,
} from "./styles";

const svg = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='13'
    height='21'
    viewBox='0 0 13 21'
    fill='none'
  >
    <path
      d='M0.778809 10.8916C0.778809 11.2847 0.924805 11.6216 1.23926 11.9136L9.97656 20.4712C10.2236 20.7183 10.5381 20.853 10.9087 20.853C11.6499 20.853 12.2451 20.269 12.2451 19.5166C12.2451 19.146 12.0879 18.8203 11.8408 18.562L3.96826 10.8916L11.8408 3.22119C12.0879 2.96289 12.2451 2.62598 12.2451 2.2666C12.2451 1.51416 11.6499 0.930176 10.9087 0.930176C10.5381 0.930176 10.2236 1.06494 9.97656 1.31201L1.23926 9.8584C0.924805 10.1616 0.778809 10.4985 0.778809 10.8916Z'
      fill='black'
    />
  </svg>
);

const SuccessPage: React.FC = () => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");

  const address = "https://m81x.8thwall.app/moloch";//`https://moloch.m81x.xyz/?ar=${id}`;
  return (
    <PageContainer>
      <Header id={1} />
      <GoBackContainer>
        <GoBack onClick={() => navigate(-1)}>{svg}</GoBack>
      </GoBackContainer>
      <StyledSuccessContainer>
        <StyledSuccessTitle>Thank you</StyledSuccessTitle>
        <SuccessContainerSecondary>
          <SuccessDescPrimary>
            for bearing witness to this special garment.
          </SuccessDescPrimary>
          <SuccessDescSecondary>
            A handprint will be added to the cave representing you as a witness.
          </SuccessDescSecondary>
          <SuccessDescSecondary>
            Also check your wallet later for a POAP
          </SuccessDescSecondary>
        </SuccessContainerSecondary>
        <CaveButton href={address}>Go to the cave</CaveButton>
      </StyledSuccessContainer>
      <Footer success={true} />
    </PageContainer>
  );
};

export default SuccessPage;
