import styled from "@emotion/styled";

export const StyledEvent = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  max-width: 500px;
  padding: 16px;
  margin-top: 60px;
`;
