import React from "react";
import { StyledHistoryTitle } from "./styles";

const HistoryTitle: React.FC<{ title: string }> = ({ title }) => {
  return (
    <StyledHistoryTitle>
      The history of <br /> "{title}"
    </StyledHistoryTitle>
  );
};

export default HistoryTitle;
