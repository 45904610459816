import React, { useEffect, useState } from "react";
import { AccountWalletData, WalletDataContainer } from "./styles";

const WalletData: React.FC = () => {
  const shortWallet = (str: string) => {
    return str.slice(0, 5) + "..." + str.slice(-4);
  };
  const [address, setAddress] = useState("");

  useEffect(() => {
    setAddress(localStorage.getItem("address") || "");
  }, []);

  return (
    <WalletDataContainer>
      <AccountWalletData>{shortWallet(address)}</AccountWalletData>
    </WalletDataContainer>
  );
};

export default WalletData;
