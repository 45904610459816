import { VideoNftContainer } from "./styles";

const ImgNFT: React.FC<{ src: string }> = ({ src }) => {
  return (
    <VideoNftContainer>
      <video src={src} width='100%' autoPlay muted loop playsInline />
    </VideoNftContainer>
  );
};

export default ImgNFT;
