const Minus = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='2'
      viewBox='0 0 18 2'
      fill='none'
    >
      <rect width='18' height='2' rx='1' fill='black' />
    </svg>
  );
};

export default Minus;
