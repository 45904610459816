import { useEffect, useState } from "react";
import contractInstance from "../../blockchain/web3";
import { eventNames } from "../MainButton/MainButton";
import {
  WitnessesContainer,
  WitnessesItem,
  WitnessesList,
  WitnessesTitle,
} from "./styles";

const Witnesses: React.FC<{ eventID: number; nftID: string }> = ({
  eventID,
  nftID,
}) => {
  const [witnesses, setWitnesses] = useState<string[]>();

  const getWitnesses = async () => {
    const eventAddresses = await contractInstance.methods
      .getInstanceEventWitnessAddresses(eventNames[Number(nftID) - 1], eventID)
      .call();
    setWitnesses(eventAddresses);
  };

  useEffect(() => {
    getWitnesses();
  }, []);

  const shortWallet = (str: string) => {
    return str.slice(0, 5) + "..." + str.slice(-4);
  };

  return (
    <WitnessesContainer>
      <WitnessesTitle>Witnesses</WitnessesTitle>
      <WitnessesList>
        {witnesses?.length === 0
          ? "No witness yet"
          : !witnesses
          ? null
          : witnesses.map((witness, id) => (
              <WitnessesItem key={id}>{shortWallet(witness)}</WitnessesItem>
            ))}
      </WitnessesList>
    </WitnessesContainer>
  );
};

export default Witnesses;
