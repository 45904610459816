import styled from "@emotion/styled";

export const StyledHistoryTitle = styled("h1")(() => ({
  fontSize: 28,
  fontStyle: "italic",
  fontWeight: 800,
  lineHeight: "36px",
  letterSpacing: -0.41,
  textAlign: "center",
  margin: "0 0 30px",
}));
