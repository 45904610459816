import styled from "@emotion/styled";

export const WitnessesContainer = styled("div")(() => ({
  marginTop: 10,
  maxWidth: 500,
}));

export const WitnessesTitle = styled("h3")(() => ({
  fontSize: 20,
  fontWeight: 700,
  lineHeight: "16px",
  letterSpacing: -0.41,
}));

export const WitnessesList = styled("div")`
  margin-top: 30px;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  max-height: 155px;
  overflow-x: scroll;
  display: flex;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const WitnessesItem = styled("span")(() => ({
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  letterSpacing: -0.41,
  textAlign: "left",
  marginBottom: 5,
  minWidth: 115,
}));
