import React, { useCallback, useEffect } from "react";
import { web3Modal } from "../../blockchain/providerOptions";
import ConnectStateButton from "../ConnectStateButton";
import WalletData from "../WalletData";
import { HeaderLogo, StyledHeader, HeaderLogoContainer } from "./styles";
import { providers } from "ethers";
import { useDispatch } from "react-redux";
import { AppDispatch, useAppSelector } from "../../redux";
import { resetProvider, setAddress, setProvider } from "../../redux/auth/slice";

const mainLogo = require("../../static/img/m81_logo.jpg");

const Header: React.FC<{ id: number }> = ({ id }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { provider, web3Provider } = useAppSelector((state : any) => state.wallet);

  const connect = useCallback(async () => {
    const provider = await web3Modal.connect();
    const web3Provider = new providers.Web3Provider(provider);
    const signer = web3Provider.getSigner();
    const address = await signer.getAddress();
    const network = await web3Provider.getNetwork();

    localStorage.setItem("address", address);

    dispatch(
      setProvider({
        provider,
        web3Provider,
        address,
        chainId: network.chainId,
      })
    );
  }, [dispatch]);

  const disconnect = useCallback(
    async function () {
      await web3Modal.clearCachedProvider();
      if (provider?.disconnect && typeof provider.disconnect === "function") {
        await provider.disconnect();
      }
      localStorage.removeItem("address");
      resetProvider();
    },
    [provider]
  );

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      connect();
    }
  }, [connect]);

  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (accounts: string[]) => {
        console.log("accountsChanged", accounts);
        dispatch(setAddress(accounts[0]));
      };

      const handleChainChanged = (_hexChainId: string) => {
        window.location.reload();
      };

      const handleDisconnect = (error: { code: number; message: string }) => {
        console.log("disconnect", error);
        disconnect();
      };

      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);
      provider.on("disconnect", handleDisconnect);

      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("chainChanged", handleChainChanged);
          provider.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [provider, dispatch, disconnect]);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", async () => {
        await connect();
      });
    }
  }, [connect]);

  return (
    <StyledHeader>
      <HeaderLogoContainer>
        <HeaderLogo src={mainLogo}></HeaderLogo>
      </HeaderLogoContainer>
      {web3Provider ? <WalletData /> : <ConnectStateButton connect={connect} />}
    </StyledHeader>
  );
};

export default Header;
