const Plus = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
  >
    <rect y='8' width='18' height='2' rx='1' fill='black' />
    <rect
      x='8'
      y='18'
      width='18'
      height='2'
      rx='1'
      transform='rotate(-90 8 18)'
      fill='black'
    />
  </svg>
);

export default Plus;
