import React from "react";
import {
  TitleNftContainer,
  TitleNftDescription,
  TitleNftHeader,
  TitleNftTitle,
} from "./styles";

const TitleNft: React.FC<{ description: string; title: string }> = ({
  description,
  title,
}) => {
  const res = description
    .split("")
    .slice(0, description.lastIndexOf("["))
    .join("");

  const arr = [
    res.slice(0, res.indexOf("[")),
    res.slice(res.indexOf(")") + 1, res.length),
  ];
  return (
    <TitleNftContainer>
      <TitleNftHeader>
        <TitleNftTitle>{title}</TitleNftTitle>
      </TitleNftHeader>
      <TitleNftDescription>
        {arr[0]}
        {description && (
          <a
            style={{ textDecoration: "none", color: "#007AFF" }}
            href='https://slatestarcodex.com/2014/07/30/meditations-on-moloch/'
            target='blank'
          >
            the god of coordination failure
          </a>
        )}
        {arr[1]}
      </TitleNftDescription>
    </TitleNftContainer>
  );
};

export default TitleNft;
