import React, { useEffect, useState } from "react";
import SmartContractsInfo from "../../blockchain/ContractInfo.json";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import TitleNft from "../../components/TitleNft";
import EventContainer from "../../components/EventContainer";
import { NftData } from "../../types/nftData";
import CurrentEvent from "../../components/CurrentEvent";

import { PageContainer } from "./styles";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { AddGnosisNetwork } from "../../blockchain/MetaMaskConnect";

const { address } = SmartContractsInfo.smartContractNFT;

const ConnectPage: React.FC = () => {
  const [nftData, setNftData] = useState<NftData>({
    animation_url: "",
    name: "",
    description: "",
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const search = useLocation().search;
  const params = useParams();
  console.log(
    search,
    new URLSearchParams(search).get("id"),
    params,
    searchParams.get("id")
  );
  const nftFromUrl: string =
    new URLSearchParams(search).get("?=nft") || searchParams.get("id") || "";

  const nftID = (id: string | number): number => {
    id = Number(id);
    if (id > 0 && id <= 3) {
      return id;
    }
    if (isNaN(id)) {
      return 1;
    }
    return 3;
  };

  // useEffect(() => {
  //   const checkIsRightNetwork = async () => {
  //     const isRight = await checkNetwork(networkID);
  //     if (!isRight) {
  //       await switchEthereumChain(networkID);
  //     }
  //   };
  //   checkIsRightNetwork();
  // }, []);

  useEffect(() => {
    const updateNFT = async (nftID: number) => {
      await getOsNFTInfo(nftID);
      await AddGnosisNetwork();
    };
    updateNFT(nftID(nftFromUrl));
  }, [nftFromUrl]);

  const getOsNFTInfo = async (nftID: number) => {
    const options = { method: "GET" };
    const { animation_url, name, description } = await fetch(
      "https://api.opensea.io/api/v1/asset/" +
        address +
        "/" +
        nftID +
        "/?include_orders=false",
      options
    )
      .then((response) => response.json())
      .catch((err) => console.error(err));
    return setNftData({ animation_url, name, description });
  };
  console.log("nftID #", nftID(nftFromUrl).toString());

  return (
    <PageContainer>
      <Header id={nftID(nftFromUrl)} />
      <CurrentEvent nftID={nftID(nftFromUrl).toString()} nftData={nftData} />
      <EventContainer nftID={nftID(nftFromUrl).toString()} />
      <TitleNft description={nftData.description} title={nftData.name} />
      <Footer success={true} />
    </PageContainer>
  );
};

export default ConnectPage;
