import styled from "@emotion/styled";
import { Link } from "@mui/material";

export const StyledSuccessTitle = styled("h1")(() => ({
  fontWeight: 700,
  fontSize: 49,
  textAlign: "center",
  lineHeight: "96px",
  letterSpacing: -0.41,
}));

export const StylePoapText = styled("h4")(() => ({
  fontWeight: 700,
  fontSize: 24,
  textAlign: "center",
}));

export const GoBack = styled("button")(() => ({
  cursor: "pointer",
  border: "none",
  marginBottom: 40,
}));

export const CaveButton = styled(Link)(() => ({
  cursor: "pointer",
  padding: "10px 16px",
  background: "#000000",
  color: "white",
  maxWidth: 150,
  borderRadius: 13,
  textAlign: "center",
  backdropFilter: "blur(40px)",
  margin: "30px auto",
}));

export const GoBackContainer = styled("div")(() => ({
  background: "#F5F5F5",
  marginTop: 70,
  textAlign: "left",
  padding: "19px 16px 0",
  width: "100%",
  maxWidth: 500,
}));

export const StyledSuccessContainer = styled("div")(() => ({
  height: "calc(100vh - 175px)",
  padding: "14px 15px 74px",
  background: "#F5F5F5",
  maxWidth: 500,
  display: "flex",
  flexDirection: "column",
}));

export const SuccessContainerSecondary = styled("div")(() => ({
  padding: "0 12px",
  marginTop: 5,
}));

export const SuccessDescPrimary = styled("p")(() => ({
  fontSize: 24,
  fontWeight: 600,
  lineHeight: "36px",
  letterSpacing: -0.41,
  textAlign: "center",
}));

export const SuccessDescSecondary = styled("p")(() => ({
  fontSize: 18,
  fontWeight: 500,
  lineHeight: "27px",
  letterSpacing: -0.41,
  marginTop: 28,
  textAlign: "center",
}));
