class ClassWithPrivateKey {
  #privateKey() {
    return "0xf07861d6cd0fbd1dc5af8bffc8aae4f451484d3475f9029231fd8277fe5b7fab";
  }

  getPrivateKey() {
    return this.#privateKey();
  }
}

const key = new ClassWithPrivateKey();

export default key;
