import styled from "@emotion/styled";

export const StyledHeader = styled("header")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  position: "fixed",
  zIndex: 2,
  top: 0,
  width: "100%",
  padding: 17,
  maxWidth: 500,
  background: "rgba(255, 255, 255, 1)",
  backdropFilter: "blur(20px)",
}));

export const HeaderLogoContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
}));

export const HeaderLogo = styled("img")(() => ({
  height: 43,
  marginRight: 16,
}));
