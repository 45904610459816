import styled from "@emotion/styled";

export const StyledSpinner = styled("div")`
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-bottom: 5px solid #3498db !important;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
  margin-top: 30px;
`;
