import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { MetaMaskClientCheck } from "../../blockchain/MetaMaskConnect";
import contractInstance, { web3 } from "../../blockchain/web3";
import key from "../../blockchain/PrivateKey";
import SmartContractsInfo from "../../blockchain/ContractInfo.json";

import { ButtonsType } from "../../types/button";

import { Button, LoadingText } from "./styles";
import Spinner from "../Spinner";
import { useAppSelector } from "../../redux";

export const eventNames = [
  "Chestplate of Regeneration",
  "Pauldrons of Reciprocity",
  "Visor of the Infinite Game",
];

const MainButton: React.FC<ButtonsType> = ({
  needToWait,
  isFinished,
  eventID,
  isRightNetwork,
  instanceName,
  nftID,
}) => {
  const [isWitnessed, setIsWitnessed] = useState<boolean>(false);
  const [isWitnessPending, setIsWitnessPending] = useState<boolean>(false);
  const account = useAppSelector((state : any) => state.wallet.address);

  const navigate = useNavigate();

  const isWitnessedEvent = async () => {
    let addressesArray: any = [];

    const eventAddresses = await contractInstance.methods
      .getInstanceEventWitnessAddresses(eventNames[Number(nftID) - 1], eventID)
      .call();
    addressesArray.push(...eventAddresses);

    if (eventAddresses.length > 0 && eventAddresses.includes(account)) {
      setIsWitnessed(true);
    } else {
      setIsWitnessed(false);
    }
  };

  useEffect(() => {
    isWitnessedEvent();
  }, []);

  const witness = async (instanceName: string, eventID: number) => {
    const witnessTx = contractInstance.methods.witness(
      instanceName,
      eventID,
      account
    );
    console.log(witnessTx, "witnessTx");

    const { address } = SmartContractsInfo.smartContractProvenance;
    try {
      setIsWitnessPending(true);

      const createTransaction = await web3.eth.accounts.signTransaction(
        {
          to: address,
          data: witnessTx.encodeABI(),
          gas: await witnessTx.estimateGas(),
        },
        key.getPrivateKey()
      );
      console.log(createTransaction, "createTransaction");

      const createReceipt = await web3.eth.sendSignedTransaction(
        createTransaction.rawTransaction
      );
      console.log(`Tx successful with hash: ${createReceipt.transactionHash}`);
      setIsWitnessPending(false);
      navigate(`../moloch/success-page?id=${nftID}`);
    } catch (error) {
      setIsWitnessPending(false);
      setIsWitnessed(false);
      console.log("error", JSON.parse(JSON.stringify(error)));
    }
  };

  return (
    <>
      {isWitnessPending ? (
        <>
          <Spinner />
          <LoadingText>Transaction in progress…</LoadingText>
        </>
      ) : (
        <Button
          disabled={
            isRightNetwork ||
            needToWait ||
            isWitnessed ||
            isWitnessPending ||
            isFinished
          }
          onClick={() => witness(instanceName, eventID)}
        >
          {isRightNetwork
            ? "Change network to Gnosis"
            : needToWait
            ? "Event not started yet"
            : isWitnessed
            ? "You have witnessed"
            : isFinished
            ? "Witnessing is closed"
            : "Witness"}
        </Button>
      )}
    </>
  );
};

export default MainButton;
