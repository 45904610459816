import React, { useEffect } from "react";
import Minus from "../../static/icons/Minus";
import Plus from "../../static/icons/Plus";
import { EventData } from "../../types/provenanceData";
import ButtonsContainer from "../ButtonsContainer";
import Carousel from "../Carousel";
import Witnesses from "../Witnesses";
import {
  AccordeonBody,
  AccordeonBodyData,
  AccordeonBodyHeader,
  AccordeonBodyLocation,
  AccordeonButton,
  AccordeonContainer,
  AccordeonHeader,
  AccordeonTitle,
  EndData,
  StartData,
} from "./styles";

const Accordeon: React.FC<{
  event: EventData;
  instanceName: string;
  nftID: string;
}> = ({ event, instanceName, nftID }) => {
  const [isShowedAcc, setIsShowedAcc] = React.useState(false);
  const hash = window.location.hash.substring(1);
  const el = document.getElementById(`${hash}`);

  const dateNow = new Date();
  const needToWait = dateNow.getTime() > event.startDate * 1000;
  const isFinished = dateNow.getTime() > event.endDate * 1000;
  const current = needToWait && !isFinished;

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      el?.scrollIntoView();
      window.history.pushState(null, document.title, window.location.search);
    }, 1000);
    return () => {
      clearTimeout(timeoutID);
    };
  }, [el]);

  useEffect(() => {
    if (window.localStorage.getItem(`showEvent${event.eventId}`)) {
      setIsShowedAcc(
        !!window.localStorage.getItem(`showEvent${event.eventId}`) || false
      );
    }
    return () => window.localStorage.removeItem(`showEvent${event.eventId}`);
  }, [event.eventId]);

  const handleShowAcc = () => {
    setIsShowedAcc(!isShowedAcc);
    if (isShowedAcc) {
      window.localStorage.removeItem(`showEvent${event.eventId}`);
    } else {
      window.localStorage.setItem(`showEvent${event.eventId}`, "true");
    }
  };

  function padTo2Digits(num: number) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(d: number) {
    const date = new Date(d * 1000);
    return [
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
      date.getFullYear(),
    ].join(".");
  }

  return (
    <>
      {!current ? (
        <AccordeonContainer id={"e" + event.eventId.toString()}>
          <AccordeonHeader>
            <AccordeonTitle>{event.title}</AccordeonTitle>
            <AccordeonButton onClick={handleShowAcc}>
              {!isShowedAcc ? <Plus /> : <Minus />}
            </AccordeonButton>
          </AccordeonHeader>
          {isShowedAcc && (
            <AccordeonBody>
              <AccordeonBodyHeader>
                <AccordeonBodyData>
                  <StartData>
                    Start {formatDate(Number(event.startDate))}
                  </StartData>
                  <br />
                  <EndData>End {formatDate(Number(event.endDate))}</EndData>
                </AccordeonBodyData>
                <AccordeonBodyLocation>{event.location}</AccordeonBodyLocation>
              </AccordeonBodyHeader>
              {event.images.length ? <Carousel images={event.images} /> : null}
              <ButtonsContainer
                instanceName={instanceName}
                needToWait={!needToWait}
                isFinished={isFinished}
                eventID={event.eventId}
                nftID={nftID}
              />
              <Witnesses eventID={event.eventId} nftID={nftID} />
            </AccordeonBody>
          )}
        </AccordeonContainer>
      ) : null}
    </>
  );
};

export default Accordeon;
