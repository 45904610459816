import styled from "@emotion/styled";

export const AccordeonContainer = styled("div")(() => ({
  width: "100%",
}));

export const AccordeonHeader = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  padding: "16px 16px 0",
}));

export const AccordeonTitle = styled("h4")(() => ({
  fontWeight: 700,
  fontSize: 16,
}));

export const AccordeonButton = styled("div")(() => ({
  cursor: "pointer",
}));

export const AccordeonBody = styled("div")(() => ({}));

export const AccordeonBodyHeader = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontSize: 12,
  color: "#828282",
  padding: "6px 16px 20px",
  borderBottom: "1px solid #000000",
}));

export const AccordeonBodyInfo = styled("div")(() => ({}));

export const AccordeonBodyData = styled("span")(() => ({}));

export const StartData = styled("span")(() => ({
  marginBottom: 3,
}));

export const EndData = styled("span")(() => ({}));

export const AccordeonBodyLocation = styled("span")(() => ({}));
